<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>จัดการลูกค้า</b></h3>
        <small>         
          <span class="text-primary" @click="$router.push(`/setting/customer/warranty/sub-company`)" style="cursor: pointer;">{{$route.query.subCompanyName || "Brand"}}</span>   >     
          <b>{{ mode == 'create' ? 'เพิ่มบริษัท' :'แก้ไขบริษัท' }}</b>
          </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">       
        <b-button
          v-if="customerPermission=='create' || customerPermission=='edit'"        
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
          <feather-icon icon="SaveIcon" />
          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
         
          @click="$router.push(`/setting/customer/warranty/sub-company?subCompanyName=${$route.query.subCompanyName}`)"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">

        
        <b-card>
          <b-row >
            <b-col><h2><b>{{ mode == 'create' ? 'เพิ่มบริษัท' :'แก้ไขบริษัท' }}</b></h2></b-col>
          </b-row>

          <!--- START (ชื่อบริษัท) --->
          <b-row class="mt-2">
            <b-col md="3" class="">
              <label>ชื่อบริษัท</label>
              <b-form-input
                placeholder="Vserv"
                size="sm"
                value="Vserv"
                v-model="subCompanyData.subCompanyName"
              />
            </b-col>
       
            <b-col md="2" class="">
              <label>สถานะ</label>
              <b-form-group>
                <b-form-radio-group
                  v-model="subCompanyData.status"
                  :options="statusOptions"
                  name="radio-inline"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--- END (ชื่อบริษัท) --->

          <!--- START (เบอร์โทร) --->
          <b-row>
            <b-col md="3" class="">
              <label>เบอร์โทร</label>
              <b-form-input
                placeholder="0987654321"
                size="sm"
                value="0987654321"
                v-model="subCompanyData.tel"
              />
            </b-col>
       
            <b-col md="3" class="">
              <label>Email</label>
              <b-form-input
                placeholder="admin@gmail.com"
                size="sm"
                value="admin@gmail.com"
                v-model="subCompanyData.email"
              />
            </b-col>
          </b-row>
          <!--- END (เบอร์โทร) --->


          <!--- START ROW รายละเอียด --->
          <b-row class="mt-1">
            <b-col md="12">
              <label>รายละเอียด</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="รายละเอียด"
                rows="2"
                size="sm"
                v-model="subCompanyData.detail"
              />
            </b-col>
          </b-row>
          <!--- END รายละเอียด --->
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import CustomerService from "@/services/setting/customer";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea, 
    BFormRadioGroup,
  },
  data() {
    return {
      statusOptions: [
        { text: 'เปิด', value: 1 },
        { text: 'ปิด', value: 0 },        
      ],
      subCompanyData:{       
        mode:"warranty",
        subCompanyName: "",
        detail: "",
        tel: "",
        email: "",
        status: 1     
      },
      isLoading:false
    };
  },

  computed:{
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003-1")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    mode(){
      const subCompanyId = this.$route.query.scid
      if(subCompanyId){
        return "edit"
      }
      return "create"
    },
    paramSubCompanyId(){
      return this.$route.query.scid
    },
    paramCompanyId(){
      return this.$route.query.cid
    }
  },


  methods: { 
    async save(){            
      if(!this.subCompanyData.subCompanyName){
        alert("กรุณากรอกชื่อ Brand")
        return
      }
      if(this.mode == 'create'){
        await this.insert()
      }else{
        await this.update()
      }
    }, 

    async getCustomerSubCompanyDetail(){
      const result = await CustomerService.GetCustomerSubCompanyById(this.paramSubCompanyId)
      this.subCompanyData = result.data
    },
    async insert(){
      this.isLoading =true
      const result = await CustomerService.InsertCustomerSubCompany({ ...this.subCompanyData, companyId:0})
      if (result.status == "success") {
        if(result.data.status == "dupplicate"){
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Brand`,
              icon: "XIcon",
              variant: "danger",
              text: `ชื่อนี้มีการใช้งานแล้ว กรุณาเปลี่ยนชื่อบริษัท`,
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Brand`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรายการเรียบร้อย`,
            },
          });
          this.$router.push(`/setting/customer/warranty/sub-company`)       
        }


                 
              
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Brand`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มรายการ`,
            },
          });
        }

      this.isLoading = false
      
    },
    async update(){ 
      this.isLoading =true
      const result = await CustomerService.UpdateCustomerSubCompany(this.subCompanyData,this.paramSubCompanyId )
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Brand`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขรายการเรียบร้อย`,
            },
          });
          this.$router.push(`/setting/customer/warranty/sub-company?subCompanyName=${this.$route.query.subCompanyName}`)              
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Brand`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขรายการ`,
            },
          });
        }
        this.isLoading = false
    }
  },

  mounted(){
    if(this.mode == "edit"){
      this.getCustomerSubCompanyDetail()
    }    
  }
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
